import Seo from "../seo";

export const Layout = ({
  title,
  image,
  children,
}: {
  title?: string;
  image?: string;
  children: any;
}) => {
  return (
    <>
      <Seo title={title} image={image}></Seo>
      <p className="sr-only">
        <a href="#page">Skip to page content</a>
      </p>
      {children}
    </>
  );
};

export default Layout;

// import { useCallback } from "react";

import { useRouter } from "next/router";
import { useProfile } from "../../providers/profile";

import pkg from "../../package.json";

import Nav from "./dashboard-nav";
import LogOut from "../log-out";

import { Avatar, Badge } from "@mui/material";
import LogoutIcon from "@mui/icons-material/Logout";
// import CloseIcon from "@mui/icons-material/Close";
// import SetupStateGuide from "./setup-state-guide";

import {
  contrast,
  hexToRgb,
  // stringAvatar,
  stringToColor,
} from "../../utilities/avatar";

export default function Sidebar() {
  const router = useRouter();

  const {
    account,
    activeProfile,
    viewProfile,
    selectProfile,
    // selectViewProfile,
  } = useProfile();

  // const closeStudent = useCallback(() => {
  //   selectViewProfile(undefined);
  //   router.push("/teacher/students");
  // }, [selectViewProfile, router]);

  return (
    <div className="flex flex-col h-full pt-4 px-5">
      <div>
        {account ? (
          <>
            <Badge
              overlap="circular"
              anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
              variant="dot"
              className="mb-4"
              sx={{
                "& .MuiBadge-badge": {
                  backgroundColor: "#44b700",
                  color: "#44b700",
                  boxShadow: "0 0 0 2px background.paper",
                  "&::after": {
                    position: "absolute",
                    top: 0,
                    left: 0,
                    width: "100%",
                    height: "100%",
                    borderRadius: "50%",
                    animation: "ripple 4.8s infinite ease-in-out",
                    border: "1px solid currentColor",
                    content: '""',
                  },
                },
                "@keyframes ripple": {
                  "75%": {
                    transform: "scale(.8)",
                    opacity: 1,
                  },
                  "100%": {
                    transform: "scale(2.4)",
                    opacity: 0,
                  },
                },
              }}
            >
              <Avatar
                onClick={() => selectProfile(undefined)}
                sx={{
                  bgcolor: stringToColor(
                    `${activeProfile?.name} ${account.name}`
                  ),
                  color: contrast(
                    hexToRgb(
                      stringToColor(`${activeProfile?.name} ${account.name}`)
                    ) || { r: 0, g: 0, b: 0 }
                  ),
                  cursor: "pointer",
                  width: 40,
                  height: 40,
                  fontSize: "1rem",
                }}
              >
                {activeProfile?.name?.charAt(0)}
                {account.name?.charAt(0)}
              </Avatar>
            </Badge>
            <div
              className={`my-2 text-xs text-[#ffffffaa] ${
                account?.roles?.some((r) =>
                  ["teacher", "admin", "superadmin"].includes(r as string)
                )
                  ? "hidden"
                  : ""
              }`}
            >
              Student
            </div>
            <div className="my-2 flex items-center text-sm">
              <div className="mr-4">
                {activeProfile?.name ? activeProfile.name : account.name}
              </div>
              <LogOut className="h-8 w-8 flex items-center justify-center rounded-full bg-rlb-orange ml-auto">
                <LogoutIcon fontSize="small" />
              </LogOut>
            </div>
          </>
        ) : null}
      </div>
      {account?.roles?.some((r) =>
        ["teacher", "admin", "superadmin"].includes(r as string)
      ) && viewProfile ? (
        <>
          <div className={`my-2 text-xs text-[#ffffffaa]`}>
            Selected Student
          </div>
          {/* <Badge
            overlap="circular"
            anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
            badgeContent={
              <Fab
                sx={{ width: "24px", height: "24px", minHeight: "unset" }}
                color="primary"
                aria-label="close selected student"
                onClick={closeStudent}
              >
                <CloseIcon fontSize="inherit" />
              </Fab>
            }
          >
            <Avatar
              {...stringAvatar(
                `${viewProfile.name?.trim()} ${viewProfile.account?.name}`
              )}
            />
          </Badge> */}
          <div className="my-2">{viewProfile.name}</div>
        </>
      ) : null}
      {/* {!account?.roles?.some((r) =>
        ["teacher", "admin", "superadmin"].includes(r as string)
      ) && !account?.isActive ? (
        <div className="mt-4 px-2 bg-[#ffffff22]">
          <h2 className="text-center p-2">Getting Started?</h2>
          <SetupStateGuide></SetupStateGuide>
        </div>
      ) : null} */}
      <div className="my-4 -mx-5 min-h-[1px] bg-[#ffffff22]"></div>
      <Nav />
      <div className="mt-4 -mx-5 min-h-[1px] bg-[#ffffff22]"></div>
      <p className="text-xs py-4">v{pkg.version}</p>
    </div>
  );
}

import Head from "next/head";

const siteTitle = "Real Brave";
const description = "Music Lessons | Music Programs";
const author = "Real Brave Audio";
const siteImage = "https://www.realbraveaudio.com/favicon.png";

export default function Seo({
  title,
  image,
}: {
  title?: string;
  image?: string;
}) {
  title = title ? `${title} | ${siteTitle}` : siteTitle;

  return (
    <Head>
      <title>{title}</title>
      <meta name="description" content={description} />
      <meta name="og:title" content={title} />
      <meta name="og:description" content={description} />
      <meta name="og:image" content={image || siteImage} />
      <meta name="og:type" content="website" />
      <meta name="twitter:card" content="summary" />
      <meta name="twitter:creator" content={author} />
      <meta name="twitter:title" content={title} />
      <meta name="twitter:description" content={description} />

      <link rel="icon" type="image/png" href="/favicon.png" />
    </Head>
  );
}

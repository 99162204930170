import Link from "next/link";
import { useProfile } from "../../providers/profile";

import AddIcon from "@mui/icons-material/Add";

const ProfileSelect = () => {
  const { account, activeProfile, selectProfile } = useProfile();

  return (
    <div className="text-center px-8">
      {account ? (
        <>
          <h1 className="text-center text-2xl mb-8">
            {account?.profiles?.items?.length
              ? "Select Your Profile:"
              : "Click the plus to create a profile!"}
          </h1>
          <div className="flex flex-wrap justify-center gap-4">
            {account?.profiles?.items
              ?.filter((p) => !p?.isDeleted)
              .map((p, i) => {
                return (
                  <div
                    key={i}
                    onClick={() => selectProfile(p!)}
                    className={`rounded-full w-24 h-24 flex items-center justify-center bg-rlb-green cursor-pointer hover:opacity-100 p-2 ${
                      p?.id === activeProfile?.id ? "opacity-100" : "opacity-80"
                    }`}
                  >
                    {p?.name}
                  </div>
                );
              })}
            <Link
              href="/create-profile"
              className="rounded-full w-24 h-24 flex items-center justify-center bg-rlb-orange cursor-pointer opacity-80 hover:opacity-100"
            >
              <AddIcon fontSize="large" />
            </Link>
          </div>
        </>
      ) : (
        <h1 className="text-center text-xl mb-8">Loading...</h1>
      )}
    </div>
  );
};

export default ProfileSelect;

import { useEffect, useState } from "react";
import Link from "next/link";
import { NextRouter, useRouter } from "next/router";
import { useProfile } from "../../providers/profile";

import {
  AccountBalanceWalletOutlined,
  AccountTree,
  AccountTreeOutlined,
  DashboardCustomizeOutlined,
  Event,
  MarkChatUnreadOutlined,
  MonetizationOnOutlined,
  MusicVideoOutlined,
  SettingsOutlined,
  SupervisorAccountOutlined,
  WarningAmber,
  Link as LinkIcon,
} from "@mui/icons-material";
import { Profile } from "../../src/API";

export interface DashboardNavLink {
  label: string;
  path: string;
  icon: JSX.Element;
  onlyWithViewProfile?: boolean;
}

export const navLinks: DashboardNavLink[] = [
  {
    label: "Dashboard",
    path: "/",
    icon: <DashboardCustomizeOutlined fontSize="inherit" />,
  },
  {
    label: "Lessons Schedule",
    path: "/lessons",
    icon: <Event fontSize="inherit" />,
  },
  {
    label: "Skills & Assignments",
    path: "/skills",
    icon: <AccountTreeOutlined fontSize="inherit" />,
  },
  {
    label: "Chat",
    path: "/chat",
    icon: <MarkChatUnreadOutlined fontSize="inherit" />,
  },
  {
    label: "Archive",
    path: "/archive",
    icon: <MusicVideoOutlined fontSize="inherit" />,
  },
];

export const teacherNavLinks: DashboardNavLink[] = [
  {
    label: "Dashboard",
    path: "/",
    icon: <DashboardCustomizeOutlined fontSize="inherit" />,
  },
  {
    label: "Teacher Schedule",
    path: "/teacher/lessons",
    icon: <Event fontSize="inherit" />,
  },
  {
    label: "All Students",
    path: "/teacher/students",
    icon: <SupervisorAccountOutlined fontSize="inherit" />,
  },
  {
    label: "Skill Templates",
    path: "/teacher/skill-templates",
    icon: <AccountTree fontSize="inherit" />,
  },
  {
    label: "Student Skills",
    path: "/skills",
    icon: <AccountTreeOutlined fontSize="inherit" />,
    onlyWithViewProfile: true,
  },
  {
    label: "Student Schedule",
    path: "/lessons",
    icon: <Event fontSize="inherit" />,
    onlyWithViewProfile: true,
  },
  {
    label: "Student Chat",
    path: "/chat",
    icon: <MarkChatUnreadOutlined fontSize="inherit" />,
    onlyWithViewProfile: true,
  },
];

export const adminNavLinks: DashboardNavLink[] = [...teacherNavLinks];

export const superAdminNavLinks: DashboardNavLink[] = [...adminNavLinks];

export const footerNavLinks: DashboardNavLink[] = [
  {
    label: "Billing",
    path: "/billing",
    icon: <AccountBalanceWalletOutlined fontSize="inherit" />,
  },
  {
    label: "Account Settings",
    path: "/settings",
    icon: <SettingsOutlined fontSize="inherit" />,
  },
];

export const footerTeacherNavLinks: DashboardNavLink[] = [
  {
    label: "Account Settings",
    path: "/settings",
    icon: <SettingsOutlined fontSize="inherit" />,
  },
];

export const footerAdminNavLinks: DashboardNavLink[] = [
  {
    label: "Quick Links",
    path: "/admin/links",
    icon: <LinkIcon fontSize="inherit" />,
  },
  {
    label: "Student Report",
    path: "/admin/student-report",
    icon: <SupervisorAccountOutlined fontSize="inherit" />,
    onlyWithViewProfile: true,
  },
  {
    label: "Reports",
    path: "/admin/reports",
    icon: <SupervisorAccountOutlined fontSize="inherit" />,
  },
  {
    label: "Account Creation",
    path: "/admin/account-creation",
    icon: <SupervisorAccountOutlined fontSize="inherit" />,
  },
  {
    label: "Instruments",
    path: "/admin/instruments",
    icon: <WarningAmber fontSize="inherit" />,
  },
];

export const footerSuperadminNavLinks: DashboardNavLink[] = [
  {
    label: "Locations",
    path: "/superadmin/locations",
    icon: <WarningAmber fontSize="inherit" />,
  },
  {
    label: "Pricing",
    path: "/superadmin/pricing",
    icon: <MonetizationOnOutlined fontSize="inherit" />,
  },
  {
    label: "Bill Processing",
    path: "/superadmin/bill-processing",
    icon: <AccountBalanceWalletOutlined fontSize="inherit" />,
  },
  {
    label: "PracticePad Score",
    path: "/superadmin/ppad-score",
    icon: <SupervisorAccountOutlined fontSize="inherit" />,
  },
];

const genItemList = (
  items: DashboardNavLink[],
  router: NextRouter,
  viewProfile: Profile | undefined
) => {
  return (
    <ul className="text-sm">
      {items.map((item, i) => {
        if (!item.label || (item.onlyWithViewProfile && !viewProfile)) return;
        return (
          <li key={i}>
            <Link
              href={item.path}
              className={`flex items-center py-3 ${
                item.path === router.route ? "text-rlb-orange" : ""
              }`}
            >
              <div className="mr-2 flex items-center">{item.icon}</div>
              <div className="flex-1">{item.label}</div>
            </Link>
          </li>
        );
      })}
    </ul>
  );
};

export default function Nav() {
  const [navItems, setNavItems] = useState<DashboardNavLink[]>([]);
  const [footerItems, setFooterItems] = useState<DashboardNavLink[]>([]);
  const { account, viewProfile } = useProfile();
  const router = useRouter();

  useEffect(() => {
    if (!account) return;

    if (account.roles?.includes("superadmin")) {
      setNavItems(superAdminNavLinks);
      setFooterItems([
        ...footerAdminNavLinks,
        ...footerSuperadminNavLinks,
        ...footerNavLinks,
      ]);
    } else if (account.roles?.includes("admin")) {
      setNavItems(adminNavLinks);
      setFooterItems([...footerAdminNavLinks, ...footerNavLinks]);
    } else if (account.roles?.includes("teacher")) {
      setNavItems(teacherNavLinks);
      setFooterItems(footerTeacherNavLinks);
    } else {
      setNavItems(navLinks);
      setFooterItems(footerNavLinks);
    }
  }, [account, setNavItems]);

  return (
    <>
      <nav aria-label="Primary Navigation">
        {genItemList(navItems, router, viewProfile)}
      </nav>
      <nav aria-label="Secondary Navigation" className="mt-auto">
        {genItemList(footerItems, router, viewProfile)}
      </nav>
    </>
  );
}

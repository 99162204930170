import { useAuth0 } from "@auth0/auth0-react";
// import { Auth } from "@aws-amplify/auth";
import { Auth } from "aws-amplify";

export default function LogOut({
  className,
  children,
}: {
  className?: string;
  children?: any;
}) {
  const { logout } = useAuth0();
  const federatedLogout = async () => {
    if (typeof window !== "undefined") {
      localStorage.removeItem("realbrave_profile_id");
      localStorage.removeItem("realbrave_view_profile_id");
    }
    // await DataStore.clear();
    await Auth.signOut();
    logout({ federated: true, returnTo: "https://www.realbraveaudio.com" });
  };

  return (
    <button onClick={federatedLogout} className={className || ""}>
      {children}
    </button>
  );
}

import { useEffect, useState } from "react";

import Image from "next/image";
import { useRouter } from "next/router";
import { useAuth0 } from "@auth0/auth0-react";
import { useProfile } from "../../providers/profile";

import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import Backdrop from "@mui/material/Backdrop";

import MenuIcon from "@mui/icons-material/Menu";
import useMediaQuery from "@mui/material/useMediaQuery";

import Root from "./root";
import ProfileSelect from "../dashboard/profile-select";
import Sidebar from "../dashboard/dashboard-sidebar";
import Twilio from "../twilio/twilio";
import { Steps } from "../../types/twilio";

import { useVideoState } from "../../providers/twilio";
import { useVideoContext } from "../../providers/video";

import HazyWaveImage from "../../media/textures/hazy-wave.png";

const drawerWidth = 225;

export const Layout = ({
  title,
  image,
  children,
}: {
  title?: string;
  image?: string;
  children: any;
}) => {
  const [drawerNavMode, setDrawerNavMode] = useState(false);
  const [drawerOpen, setDrawerOpen] = useState(false);
  const { videoOpen, setVideoOpen, setRoomStep } = useVideoState();
  const { fullScreenMode } = useVideoContext();

  const [profileDialogOpen, setProfileDialogOpen] = useState(false);

  const router = useRouter();
  const { account, activeProfile, isInLesson } = useProfile();
  const [pageRefresh, setPageRefresh] = useState(false);
  const deskSize = useMediaQuery("(min-width:600px)", { noSsr: true });

  const { isLoading, isAuthenticated } = useAuth0();
  useEffect(() => {
    // check 1: is authenticated
    if (!isLoading && !isAuthenticated) {
      router.push("/authorize");
      return;
    }

    // check 2: set profile
    if (!activeProfile) {
      if (router.asPath !== "/create-profile") {
        setProfileDialogOpen(true);
      } else {
        setProfileDialogOpen(false);
      }
      return;
    }
    setProfileDialogOpen(false);

    // check 3. is in lesson
    if (isInLesson && !pageRefresh) {
      //the use was actively in a lesson when the page refreshsed
      //automatically rejoin that resson
      setVideoOpen(true);
      setRoomStep(Steps.deviceSelectionStep);
    }
  }, [
    router,
    isLoading,
    isAuthenticated,
    account,
    activeProfile,
    isInLesson,
    pageRefresh,
    setVideoOpen,
    setRoomStep,
  ]);

  useEffect(() => {
    const onRouteChangeStart = () => {
      setDrawerOpen(false);
      setPageRefresh(true);
    };

    const onRouteChangeComplete = () => {
      setPageRefresh(false);
    };

    router.events.on("routeChangeStart", onRouteChangeStart);
    router.events.on("routeChangeComplete", onRouteChangeComplete);

    return () => {
      router.events.off("routeChangeStart", onRouteChangeStart);
      router.events.off("routeChangeComplete", onRouteChangeComplete);
    };
  }, [router, setDrawerOpen, setPageRefresh]);

  const handleDrawerToggle = () => {
    setDrawerOpen(!drawerOpen);
  };

  useEffect(() => {
    setDrawerNavMode(!deskSize || videoOpen);
  }, [deskSize, videoOpen]);

  return (
    <Root title={title} image={image}>
      <Box
        component="button"
        onClick={handleDrawerToggle}
        sx={{
          alignItems: "center",
          backgroundColor: "white",
          borderRadius: "9999px",
          color: "var(--realbrave-orange)",
          display: () => (drawerNavMode ? "flex" : "none"),
          height: "3rem",
          justifyContent: "center",
          left: "0.5rem",
          position: "fixed",
          top: "0.5rem",
          width: "3rem",
          zIndex: 10,
        }}
      >
        <MenuIcon fontSize="large" />
      </Box>
      <div className="min-h-screen w-full flex flex-col">
        <div className="flex flex-1">
          <Box
            component="aside"
            sx={{
              width: () => (drawerNavMode ? "unset" : drawerWidth),
              flexShrink: () => (drawerNavMode ? "unset" : 0),
            }}
          >
            <Drawer
              container={globalThis?.window?.document?.body}
              variant="temporary"
              open={drawerOpen}
              onClose={handleDrawerToggle}
              ModalProps={{
                keepMounted: true, // Better open performance on mobile.
              }}
              sx={{
                display: () => (drawerNavMode ? "block" : "none"),
                "& .MuiDrawer-paper": {
                  boxSizing: "border-box",
                  width: drawerWidth,
                  backgroundColor: "var(--rlb-green)",
                  color: "white",
                },
              }}
            >
              <Sidebar />
            </Drawer>
            <Drawer
              variant="permanent"
              sx={{
                display: () => (drawerNavMode ? "none" : "block"),
                "& .MuiDrawer-paper": {
                  boxSizing: "border-box",
                  width: drawerWidth,
                  backgroundColor: "var(--rlb-green)",
                  color: "white",
                },
              }}
              open
            >
              <Sidebar />
            </Drawer>
          </Box>
          <main id="page" className="flex-1 relative">
            {!fullScreenMode ? (
              <div
                id="background-image"
                className="absolute left-0 top-0 w-full h-full"
              >
                <Image
                  alt=""
                  src={HazyWaveImage}
                  fill={true}
                  className="object-cover"
                ></Image>
              </div>
            ) : null}
            <div className="absolute left-0 top-0 w-full h-full flex flex-col lg:flex-row-reverse">
              {videoOpen ? (
                <div
                  id="twilio-container"
                  className={
                    fullScreenMode
                      ? "h-full w-full"
                      : "h-1/2 lg:h-full lg:w-1/2"
                  }
                >
                  <Twilio></Twilio>
                </div>
              ) : null}
              {!fullScreenMode ? (
                <div
                  id="lesson-container"
                  className={
                    videoOpen ? "h-1/2 lg:h-full lg:w-1/2" : "h-full w-full"
                  }
                >
                  {children}
                </div>
              ) : null}
            </div>
          </main>
        </div>
      </div>
      <Backdrop
        sx={{
          alignItems: "flex-start",
          backgroundColor: "rgba(0, 0, 0, 0.8)",
          color: "#fff",
          paddingTop: "4rem",
          paddingBottom: "4rem",
          zIndex: (theme) => theme.zIndex.drawer + 1,
          overflow: "auto",
        }}
        open={profileDialogOpen}
      >
        <ProfileSelect />
      </Backdrop>
    </Root>
  );
};

export default Layout;
